(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/customized-feedback-handler/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/customized-feedback-handler/assets/javascripts/constants.js');
"use strict";


const ErrorCode = {
  TECHNICAL_ERROR: 'VET_TECHNICAL_ERROR',
  NOT_ENOUGH_FUNDS: 'VET_NOT_ENOUGH_FUNDS',
  RESPONSIBLE_GAMING_LIMIT_EXCEEDED: 'VET_RESPONSIBLE_GAMING_LIMIT_EXCEEDED',
  ODDS_CHANGED: 'VET_ODDS_CHANGED'
};
const handledErrors = [ErrorCode.TECHNICAL_ERROR, ErrorCode.NOT_ENOUGH_FUNDS, ErrorCode.RESPONSIBLE_GAMING_LIMIT_EXCEEDED, ErrorCode.ODDS_CHANGED];
setGlobal('svs.oddset_kambi.components.customizedFeedbackHandler.constants', {
  ErrorCode,
  handledErrors
});

 })(window);