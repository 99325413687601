(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/customized-feedback-handler/assets/javascripts/handler.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/customized-feedback-handler/assets/javascripts/handler.js');
"use strict";


const {
  ErrorCode
} = svs.oddset_kambi.components.customizedFeedbackHandler.constants;
const {
  account_balance: accountBalanceComponent
} = svs.components;
const {
  hideBetslip,
  showBetslip
} = svs.oddset_kambi.components.wapiIntegration;
const {
  checkRestricitions,
  displayGenericErrorDialog,
  onInsufficientFunds,
  onLimitExceeded,
  onOddsChanged
} = svs.components.oddset.clientErrorHandler.errorDialogs;
const handleCustomizedFeedback = event => {
  hideBetslip();
  switch (event.feedback.type) {
    case ErrorCode.TECHNICAL_ERROR:
      accountBalanceComponent.get(true, balance => {
        if (parseInt(balance, 10) === 0) {
          onInsufficientFunds(showBetslip);
        } else {
          checkRestricitions(showBetslip);
        }
      });
      break;
    case ErrorCode.NOT_ENOUGH_FUNDS:
      onInsufficientFunds(showBetslip);
      break;
    case ErrorCode.RESPONSIBLE_GAMING_LIMIT_EXCEEDED:
      onLimitExceeded(showBetslip);
      break;
    case ErrorCode.ODDS_CHANGED:
      onOddsChanged(showBetslip);
      break;
    default:
      displayGenericErrorDialog(showBetslip);
      break;
  }
};
setGlobal('svs.oddset_kambi.components.customizedFeedbackHandler.handleCustomizedFeedback', handleCustomizedFeedback);

 })(window);